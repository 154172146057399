import type { FilterCompleteResponse, FollowResponse, FrameResponse, PacketResponse, TapResponse, DownloadResponse } from '../types';

import { FollowEnum } from '../types';


const ALLOWED_TAP_KEYS = new Set(Array.from({ length: 15 }, (_, i) => `tap${i}`));

// {
//     "filename": "/uploads/sniff1.pcap",
//     "file_type": "Wireshark/tcpdump/... - pcap",
//     "file_length": 18142484,
//     "file_encap_type": "Ethernet",
//     "packet_count": 19195,
//     "start_time": 1535315446.458243,
//     "stop_time": 1535315684.322809,
//     "elapsed_time": 237.864566
// }
interface Summary {
  packet_count: number,
  elapsed_time: number,
  start_time: number,
  stop_time: number,
  filename: string,
  file_type: string,
  file_encap_type: string,
  file_length: number,
}

export interface TapInput {
  [key: string]: string;
}


export interface IApi {
  load(url: string, opts: {
    progress?: (loaded: number, total: number) => void
  }): Promise<any>;
  loadFromBuffer(buffer: ArrayBuffer, filename?: string): Promise<any>;
  frames(filter: string, { skip, limit }: {
    skip?: number,
    limit?: number,
  }): Promise<{
    frames: PacketResponse[],
    matched: number,
  }>;
  frame({
    frame,
    prev_frame,
    bytes,
    proto,
  }: {
    frame: number,
    prev_frame?: boolean,
    bytes?: boolean,
    proto?: boolean,
  }): Promise<FrameResponse>;
  status(): Promise<Summary>;
  follow(protocol: FollowEnum, filter: string): Promise<FollowResponse>;
  completeFilter(field: string): Promise<{ fields: FilterCompleteResponse[] }>;
  checkFilter(filter: string): Promise<{ ok: boolean; error?: string }>;
  tap(value: TapInput): Promise<{
    error: string;
    taps: TapResponse[];
  }>
  download(req: string): Promise<DownloadResponse>;
}

export class Api implements IApi {
  worker: Worker;
  private summary?: Summary;

  constructor(worker: Worker) {
    this.worker = worker;
  }

  get loaded() {
    return this.summary !== undefined;
  }

  async _request<T>(action: string, params?: any): Promise<T> {
    return new Promise((res, rej) => {
      const channel = new MessageChannel();
      channel.port1.onmessage = ({ data }) => {
        channel.port1.close();
        if (data.error) {
          rej(data.error);
        } else {
          res(data);
        }
      };
      this.worker.postMessage({ type: action, ...params }, [channel.port2]);
    });
  }

  async load(url: string, { progress }: { progress?: (loaded: number, total: number) => void }) {
    const resp = await fetch(url).then(r => r.json());
    // Download the file with progress tracking
    const xhr = new XMLHttpRequest();
    xhr.open("GET", resp.url, true);
    xhr.responseType = "arraybuffer";
    xhr.onprogress = (e) => {
      if (progress) {
        progress(e.loaded, e.total);
      }
    };
    xhr.send();
    await new Promise((res, rej) => {
      xhr.onload = () => {
        if (xhr.status === 200) {
          res(xhr);
        } else {
          rej(xhr);
        }
      };
    });
    const buffer = await xhr.response;
    return { file: buffer, filename: resp.filename }
    // return await this.loadFromBuffer(buffer, resp.filename);
  }

  async loadFromBuffer(buffer: ArrayBuffer, filename: string = "noname.pcap") {
    return new Promise((res, rej) => {
      const channel = new MessageChannel();
      channel.port1.onmessage = (event) => {
        channel.port1.close();
        if (event.data.error) {
          rej(event.data.error);
        } else {
          this.summary = event.data.result.data.summary;
          res(event.data.result.data);
        }
      };
      this.worker.postMessage({ type: "process-data", data: buffer, name: filename }, [channel.port2]);
    });
  }

  async frames(filter: string, { skip, limit }: {
    skip?: number,
    limit?: number,
  }) {
    const payload: {
      filter: string,
      skip?: number,
      limit?: number,
    } = {
      filter,
    };
    if (skip) {
      payload["skip"] = skip;
    }
    if (limit) {
      payload["limit"] = limit;
    }
    const resp = await this._request<{
      result: {
        frames: PacketResponse[],
        matched: number,
      }
    }>(
      "frames", payload
    );
    return resp.result
  }

  async frame({
    frame,
    prev_frame = true,
    bytes = true,
    proto = true,
  }: {
    frame: number,
    prev_frame?: boolean,
    bytes?: boolean,
    proto?: boolean,
  }) {
    const resp = await this._request<{
      result: FrameResponse
    }>("frame", {
      frame,
      prev_frame,
      bytes,
      proto,
    });
    return resp.result;
  }

  async status() {
    return await Promise.resolve(this.summary!);
  }

  async follow(protocol: FollowEnum, filter: string) {
    const resp = await this._request<{ result: FollowResponse }>(
      "follow", {
      follow: protocol,
      filter,
    });
    return resp.result
  }

  async completeFilter(field: string) {
    const resp = await this._request<{ result: { fields: FilterCompleteResponse[] } }>(
      "complete-filter", {
      field,
    });
    return resp.result
  }

  async checkFilter(filter: string) {
    const resp = await this._request<{ result: { ok: boolean; error?: string } }>(
      "check-filter", {
      filter,
    });
    return resp.result;
  }

  async tap(arg: TapInput) {
    for (const key in arg) {
      if (!ALLOWED_TAP_KEYS.has(key)) {
        throw new Error(`Invalid tap key: ${key}`);
      }
    }
    const resp = await this._request<{
      result: {
        error: string; taps: TapResponse[]
      }
    }>(
      "tap", { arg });
    return resp.result;
  }

  async download(arg: string) {
    const resp = await this._request<{ result: DownloadResponse }>(
      "download", { arg });
    return resp.result;
  }
}
